import Footer from '../Page/Footer'
import Header from '../Page/Header'
import SEO from '../Page/SEO'
import BlockContent from './BlockContent'
import Image from 'components/Image'
import { StaticImage } from 'gatsby-plugin-image'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type Props = {
  post: Sanity.Post
}

const Post: FC<Props> = ({ post }) => {
  const { excerpt, featuredImage, publishedAt, _rawBody, title } = post
  return (
    <div className="bg-white">
      <SEO description={excerpt} isBlogPost title={title} />
      <Header />
      <main className="h-auto relative mb-8 max-w-3xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-4">
        {featuredImage ? (
          <Image
            className="rounded-xl border-2 border-gray-100"
            image={featuredImage}
            height={400}
            width={600}
          />
        ) : (
          <StaticImage
            className="rounded-xl border-2 border-teal-300"
            src="../../assets/default-post-image.png"
            alt="Peak Performance Chiropractic"
          />
        )}
        <div className="flex justify-center max-w-7xl mx-auto py-8 px-4 sm:py-24 sm:px-6 lg:px-4">
          <div className="text-center max-w-2xl">
            <div className="flex flex-row justify-center">
              {post.tags?.map(tag => (
                <p
                  key={tag}
                  className="text-base font-semibold text-teal-500 tracking-wide uppercase mr-2"
                >
                  {tag}
                </p>
              ))}
            </div>
            <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              {title}
            </h1>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              {publishedAt}
            </p>
          </div>
        </div>
        <BlockContent blocks={_rawBody} />
      </main>
      <Footer />
    </div>
  )
}

export default Post
