import Post from 'components/Post'
import { graphql, PageProps } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type DataProps = {
  sanityPost: Sanity.Post
}

const PostTemplate: FC<PageProps<DataProps>> = ({ data }) => {
  const post = data.sanityPost
  return <Post post={post} />
}

export default PostTemplate

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    sanityPost(id: { eq: $id }) {
      ...SanityPostData
    }
  }
`
